@import "~ngx-smart-modal/ngx-smart-modal";
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
a,
a:active {
  outline: none !important;
}
textarea:focus {
  background-color: #ffff !important;
  border-color: red !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset,
    0px 0px 8px rgba(255, 0, 0, 0.5) !important;
}
.form-control:focus {
  background-color: #ffff !important;
  border-color: red !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset,
    0px 0px 8px rgba(255, 0, 0, 0.5) !important;
}

select:focus {
  background-color: #ffff !important;
  border-color: red !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset,
    0px 0px 8px rgba(255, 0, 0, 0.5) !important;
}

.btn-disabled {
  background-color: #6b0a0f !important;
}
.btn-disabled:hover {
  background-color: #500a0d !important;
}
.has-danger {
  border: 1px solid red !important ;
  box-shadow: red;
}
.card {
  width: fit-content !important;
}
.pointer {
  cursor: pointer;
}
.languageModal .nsm-content {
  background-color: #bb020b;
}

.languageModal .nsm-content .nsm-body {
  color: white;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
}

.languageModal .nsm-content .nsm-body .buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.languageModal .nsm-content .nsm-body h1 {
  font-size: 30px;
  letter-spacing: 8px;
}

.languageModal .nsm-content .nsm-body .buttons button {
  border: none;
  background-color: black;
  color: white;
  padding: 10px 15px;
  font-size: 15px;
  cursor: pointer;
  transition: 0.3s all;
  font-weight: 700;
  letter-spacing: 4px;
}

.languageModal .nsm-content .nsm-body .buttons button:hover {
  color: black;
  background-color: white;
}
/* ::ng-deep .ng-select-container {
    background-color: #ffffff!important;
    vertical-align: middle!important;
    background-size: 8px 10px!important;
    border: 1px solid #ced4da!important;
    border-radius: 0px !important;
} */

/* Modificaciones responsividad */

@media (max-width: 993px) and (min-width: 767px) {
  .navbar {
    padding-right: 4rem !important;
  }
}

.menu {
  background-color: #111111;
}

.lang-modal .nsm-content {
  background-color: #bb020b;
}

.sub-modal .nsm-content {
  background-color: #bb020b;
}
//Swiper
.swiper-button-prev {
  color: #111111;
}
.swiper-button-next {
  color: #111111;
}

.ng-lazyloading {
  background-color: transparent;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
