/* Colores*/
$red: #bb020b;
$gray: #f5f5f5;
$bottom: #111111;
$bottompriv: #141313;
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,900&display=swap");
// @import url('https://fonts.cdnfonts.com/css/gotham');

@font-face {
  font-family: "Raleway";
  src: url("/assets/fonts/Raleway/Raleway-Medium.ttf");
}

@font-face {
  font-family: "Raleway Thin";
  src: url("/assets/fonts/Raleway/Raleway-Thin.ttf");
}

@font-face {
  font-family: "Raleway Bold";
  src: url("/assets/fonts/Raleway/Raleway-Bold.ttf");
}

@font-face {
  font-family: "Raleway Light";
  src: url("/assets/fonts/Raleway/Raleway-Light.ttf");
}

.itemSlide {
  background-image: url(../imgs/slide_byob.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.itemSlideTwo {
  background-image: url(../imgs/slide_joshua.jpg);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.itemSlideThree {
  background-image: url(../imgs/slide_pony.jpg);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.itemSlideFour {
  background-image: url(../imgs/slide_tulio.jpg);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.itemCheckout {
  background-image: url(../imgs/checkOut.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  width: 100%;
  margin-top: 5rem;
}

.itemEcommerce {
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.figwhite {
  width: 60px;
  height: 90px;
  background-color: #ffff;
  position: absolute;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.figwhite:before {
  top: 5rem;
  content: "";
  width: 0;
  position: absolute;
  height: 0;
  border-top: 0px solid transparent;
  border-left: 60px solid #ffff;
  border-bottom: 40px solid transparent;
}

.figwhite:after {
  top: 5rem;
  width: 0;
  height: 0;
  margin-left: 0%;
  position: absolute;
  content: "";
  border-top: 0px solid transparent;
  border-right: 60px solid #ffff;
  border-bottom: 40px solid transparent;
}

.cuadro {
  background: white;
  position: absolute;
  width: 100%;
  top: -1rem;
  left: -0.5rem;
  transition: all 0.3s ease-in-out;
  text-decoration: unset !important;
}

.numberShop {
  color: black;
  z-index: 1;
  font-weight: 700;
  position: relative;
  top: 1rem;
  border: 2px solid black;
  padding: 0.2rem;
  border-radius: 50px;
  width: 1.5rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: unset;
}

.cuadroTwo {
  border: 1px solid #ffff;
  content: "";
  position: relative;
  background-color: transparent;
  height: 4rem;
  z-index: -2;
  top: 1rem;
}

.cuadro3 {
  height: 6rem;
}

ul.navbar-nav li.nav-item > a.nav-link {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 10pt;
  font-weight: 500;
  transition: all 0.3s ease-in;
  letter-spacing: 1px;
  border: 1px solid transparent;
  text-decoration: unset;
}

ul.navbar-nav li.nav-item > a.nav-link:hover {
  color: #ffff;
  border: 1px solid $red;
  border-radius: 0%;
}

ul.navbar-nav {
  justify-content: flex-end;
}

ul li ul {
  top: 1.9rem;
  background: #bb020b;
  position: absolute !important;
  visibility: hidden;
  min-width: 5rem;
  position: absolute;
  -webkit-transition: all 0.5s ease;
  transition: all 0.3s ease-in;
  margin-top: 2.5rem;
  right: 24rem;
  display: none;
  color: #ffff;
  list-style: none;
  text-decoration: none;
  /*font-family: "Raleway Ligth";*/
  height: 0;
  width: 20%;
}

ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  /*   opacity: 1;
 */
  height: auto;
  display: block;
  text-decoration: none;
  color: black;
}

ul li ul li {
  width: 100%;
  padding: 1rem;
  transition: all 0.3s ease-in;
  /*font-family: "Raleway Ligth";*/
  cursor: pointer;
}

ul li ul li:hover {
  background: #141313;
}

ul li ul li > a {
  text-decoration: underline;
  color: #ffff !important;
}

ul li ul > a:hover {
  text-decoration: none;
  color: #ffff !important;
}

.redes {
  background-color: $red;
  transition: all 0.3s ease-in-out;
  height: 2rem;
}

//Models
.item11 {
  grid-area: foto1;
  background-image: linear-gradient(transparent 60%, rgba(0, 0, 0, 1.5)),
    url(../imgs/ProSeriesB.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 22rem;
  transition: all 0.3s ease-in;
  cursor: pointer;
  background-position: center;
}

.item11:hover {
  background-image: linear-gradient(transparent 65%, rgba(182, 2, 11, 1.5)),
    url(../imgs/ProSeriesB.jpg);
  background-position: bottom;
}

.item22 {
  grid-area: foto2;
  background-image: linear-gradient(transparent 60%, rgba(0, 0, 0, 1.5)),
    url(../imgs/buildyourownbat.JPG);
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.4s ease-in;
  height: 44.5rem;
  cursor: pointer;
  background-position: center;
}

.item22:hover {
  background-image: linear-gradient(transparent 65%, rgba(182, 2, 11, 1.5)),
    url(../imgs/buildyourownbat.JPG);
  background-position: left;
}

.item33 {
  grid-area: foto3;
  background-image: linear-gradient(transparent 60%, rgba(0, 0, 0, 1.5)),
    url(../imgs/proseries.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s ease-in;
  height: 22rem;
  cursor: pointer;
  background-position: center;
}

.item33:hover {
  background-image: linear-gradient(transparent 65%, rgba(182, 2, 11, 1.5)),
    url(../imgs/proseries.jpg);
  background-position: right;
}

.item44 {
  grid-area: foto4;
  background-image: linear-gradient(transparent 60%, rgba(0, 0, 0, 1.5)),
    url(../imgs/youth.png);
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s ease-in;
  height: 22rem;
  cursor: pointer;
  background-position: top;
}

.item44:hover {
  background-image: linear-gradient(transparent 65%, rgba(182, 2, 11, 1.5)),
    url(../imgs/youth.png);
  background-position: center;
}

.grid-container {
  display: grid;
  grid-template-areas:
    "foto1 foto1 foto1 foto1 foto2 foto2"
    "foto1 foto1 foto1 foto1 foto2 foto2"
    "foto1 foto1 foto1 foto1 foto2 foto2"
    "foto3 foto3 foto4 foto4 foto2 foto2"
    "foto3 foto3 foto4 foto4 foto2 foto2"
    "foto3 foto3 foto4 foto4 foto2 foto2";
  background-color: transparent;
  border: 0px solid transparent;
  /* grid-gap: 5px; */
  grid-column-gap: 5px;
  grid-template-columns: auto;
}

.grid-container > a {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  /* padding: 5rem 0;*/
  font-size: 30px;
}

.line {
  width: 15%;
  height: 30%;
  border-top: 1.3px solid #ffff;
  border-left: 1.3px solid #ffff;
}

.line:nth-child(2) {
  border-right: 1.3px solid #ffff;
}

.title-notice {
  color: #ffff;
  font-weight: 350;
  font-family: "Raleway Thin";
  font-size: 16pt;
  text-decoration: none;
  letter-spacing: 3px;
}

.banner {
  background-image: url(../imgs/batCave.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 55vh;
  background-attachment: fixed;
}

.carousel-caption {
  right: 0%;
  left: 11px;
}

.card {
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #eaeaea;
  background-color: #f5f5f5;
}

.card:hover {
  border: 1px solid red;
  transform: scale(1.02);
  box-shadow: 0px 0px 9px 0px #00000034;
  background-color: #ffff;
}

.card:hover > .container {
  background-color: #f5f5f5;
  color: #bb020b !important;
}

.card:hover > .container .subBat {
  color: #bb020b;
}

.title-banner {
  padding: 1rem;
  background-color: rgba(187, 2, 11, 0.8);
  font-size: 55pt;
  letter-spacing: 2px;
  /*font-family: "Raleway";*/
  font-weight: 700;
  color: white;
  text-decoration: unset;
  /*padding-top: 3rem;*/
}

.titleBat {
  font-weight: 600;
  font-size: 15pt;
  /*font-family: "Raleway";*/
  text-decoration: unset;
  width: 210px;
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1200px) {
  .titleBat {
    width: 175px;
  }
}

@media (max-width: 992px) {
  .titleBat {
    width: 115px;
  }
}

@media (max-width: 767px) {
  .titleBat {
    width: 215px;
  }
}

.subBat {
  text-transform: uppercase;
  color: #706f6f;
  /*font-family: "Raleway Ligth";*/
  font-weight: 300;
  text-decoration: unset;
}

.priceBat {
  color: #bb020b;
  font-weight: 900;
  font-family: "Raleway Bold";
  padding-bottom: 1rem;
}

.back-footer {
  background-color: #111111;
}

.title-menu {
  color: #bb020b;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Raleway";
}

.li-menu {
  color: #ffffff;
  font-weight: 600;
  text-decoration: none !important;
  transition: all 0.3s ease-in;
  font-size: 9pt;
}

.menu-top2 {
  transition: all 0.3s ease-in;
  background-color: $red;
}

/***MENU MOVIL**/
.bar1,
.bar2,
.bar3 {
  width: 30px;
  height: 3px;
  background-color: #ffff;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-7px, 7px);
  background-color: #ffff;
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-5px, -5px);
  transform: rotate(45deg) translate(-6px, -6px);
  background-color: #ffff;
}

ul.d-flex li:hover > .li-menu {
  color: #bb020b;
}

.backCopy {
  background-color: #141313;
  height: 10vh;
}

.btn-suscribe {
  border: 1.5px solid #ffff;
  border-radius: 0%;
  color: #ffff;
  cursor: pointer;
  background-color: #bb020b;
  font-family: "Raleway";
}

.img-top {
  width: 50%;
  transition: all 0.3s ease-in-out;
}

.menu.fixed-top {
  transition: all 0.3s ease-in-out;
}

.titleSlide {
  font-size: 20pt;
  font-family: "Raleway";
  font-weight: 500;
  letter-spacing: 3px;
  color: #bb020b;
  margin-top: 1rem !important;
  /* height: 2rem; */
}

.titleModelo {
  font-family: "Raleway Bold";
  font-size: 65pt;
  font-weight: 600;
}

.textModelo {
  font-family: "Raleway Thin";
  font-size: 15pt;
  font-weight: 400;
  position: relative;
  top: 2rem;
}

.titleEco {
  font-size: 43pt;
  font-weight: 700;
  letter-spacing: 1px;
}

.redesFlex {
  align-items: top;
  position: relative;
  bottom: 1.5rem;
  transition: all 0.3s ease-out;
  display: flex;
  width: 66rem;
  align-items: center;
  justify-content: flex-end;
}

.lineIdioma {
  border: 0.8px solid #eaeaea;
  height: 1rem;
  top: 0.5rem;
  position: relative;
}

.lenguaje {
  display: flex;
  z-index: 99999999;
  width: 6.5%;
  top: -1rem;
}

.btn-banderaActive {
  border-radius: 0px;
  background: transparent;
  /* box-shadow: 0px 0px 9px 0px #eaeaea; */
  color: #ffff;
  cursor: pointer;
  height: 2rem;
  font-size: 7pt;
  font-weight: bold;
}

.btn-bandera {
  /* border: 1px solid #FFFF; */
  background: transparent;
  cursor: pointer;
  color: #ffff;
  border-radius: 0px;
  height: 2rem;
  font-size: 7pt;
  font-weight: bold;
}

.redesMovil {
  display: none;
}

.ShopMovil {
  display: none;
  cursor: pointer;
}

.titleSuscribe {
  color: #ffff;
  font-weight: 500;
  font-family: "Raleway Thin";
}

.title-bannerone {
  color: #ffff;
  letter-spacing: 40px;
  font-size: 29pt;
  font-weight: 700;
  font-family: "Raleway Bold";
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 2rem;
}

//Site Team
.lineTeam {
  border-bottom: 1.5px solid #bb020b;
  width: 5%;
  height: 1rem;
}

.backTeam {
  background-color: $red;
  color: #ffff;
  padding-top: 2%;
}

.subtitleTeam {
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Raleway";
}

.titleTeam {
  font-weight: 600;
  font-family: "Raleway";
}

.headerTeam {
  font-size: 14px !important;
  font-family: "Raleway";
  font-weight: 650;
}

.textTeam {
  font-family: "Raleway";
  font-weight: 550;
}

#mapid {
  height: 350px;
}

.style-input {
  /* border: 1.3px solid #d7d7d7!important; */
  border-radius: 0% !important;
  background-color: #ffff;
  color: #111111;
  font-weight: 500;
  padding-left: 0.5rem;
  font-family: "Raleway Ligth";
}

@media (max-width: 766px) {
  .input-responsive {
    width: 100% !important;
  }
}

.form-control {
  font-family: "Raleway Ligth";
}

.titleForm {
  font-family: "Raleway";
  font-weight: 550;
  color: #706f6f;
  font-size: 9pt;
}

.send {
  width: 100%;
  background-color: #111111;
  border-radius: 0rem;
  color: #ffff;
  font-family: "Raleway";
  font-weight: 600;
  position: relative;
  top: -0.5rem;
}

//Check
.radiodesign {
  display: block;
  position: relative;
  padding-left: 60px;
  margin-bottom: 3rem; //12px
  cursor: pointer;
  color: red;
  padding-top: 0.2rem;
  font-size: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radiodesign input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: -1.5rem;
  padding-right: 1px; //4rem
  height: 35px; //35px
  width: 45px; //37px
  background-color: #eee;
  border: 1.5px solid transparent;
  border-radius: 0%;
  transition: all 0.3s ease-in;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* On mouse-over, add a grey background color */
.radiodesign input ~ .checkmark {
  box-shadow: 4px 4px 2px 0px lightgray;
}

.radiodesign:hover input ~ .checkmark {
  background-color: #ccc;
  border: 1.5px solid #111111;
  box-shadow: 6px 6px 2px 0px lightgray;
}

/* When the radio button is checked, add a blue background */
.radiodesign input:checked ~ .checkmark {
  background-color: #ccc;
  box-shadow: 6px 6px 2px 0px lightgray;
  border: 1.5px solid #111111;
}

.radiodesign:checked {
  background: white;
}

.radiodesign:checked > #back1 {
  background-color: transparent;
  stop-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.radiodesign input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radiodesign .checkmark:after {
  left: 15px;
  top: 8px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.select-style {
  border-bottom: 1px solid #eee !important;
  border-right: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-top: 1px solid transparent !important;
  border-radius: 0rem !important;
}

#back {
  stop-color: transparent;
  transition: all 0.3s ease-in;
}

#backone {
  stop-color: transparent;
  transition: all 0.3s ease-in;
}

#backBattwo {
  stop-color: transparent;
  transition: all 0.3s ease-in;
}

#backBat {
  stop-color: transparent;
  transition: all 0.3s ease-in;
}

#back1 {
  background-color: #f2c129;
  stop-color: #f2c129;
}

#back2 {
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}

#back2Two {
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}

$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 0.875);
$input-height: 3.375rem;
$input-height-sm: 1.9375rem;
$input-height-lg: 3rem;
$input-btn-focus-width: 0.2rem;
$custom-control-indicator-bg: #252525;
$custom-control-indicator-disabled-bg: #bb020b;
$custom-control-description-disabled-color: #e2e2e2;
$white: #bb020b;
$theme-colors: (
  "primary": #e2e2e2,
);

// These variables can be used to customize the switch component.
//
$switch-height: calc(#{$input-height} * 0.8) !default;
$switch-height-sm: calc(#{$input-height-sm} * 0.8) !default;
$switch-height-lg: calc(#{$input-height-lg} * 0.8) !default;
$switch-border-radius: $switch-height !default;
$switch-bg: $custom-control-indicator-bg !default;
$switch-checked-bg: map-get($theme-colors, "primary") !default;
$switch-disabled-bg: $custom-control-indicator-disabled-bg !default;
$switch-disabled-color: $custom-control-description-disabled-color !default;
$switch-thumb-bg: $white !default;
$switch-thumb-border-radius: 50% !default;
$switch-thumb-padding: 2px !default;
$switch-focus-box-shadow: 0 0 0 $input-btn-focus-width
  rgba(map-get($theme-colors, "primary"), 0.25);
$switch-transition: 0.2s all !default;

/* SwitchOne */
.switch {
  font-size: $font-size-base;
  position: relative;

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;

    + label {
      position: relative;
      min-width: calc(#{$switch-height} * 2);
      border-radius: $switch-border-radius;
      height: $switch-height;
      line-height: $switch-height;
      display: inline-block;
      cursor: pointer;
      outline: none;
      user-select: none;
      vertical-align: middle;
      color: #bb020b;
      font-weight: 600;
      font-size: 14pt;
      text-indent: calc(calc(#{$switch-height} * 2) + 0.5rem);
    }

    + label::before,
    + label::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(#{3.7rem} * 2);
      bottom: 0;
      display: block;
      z-index: 1;
    }

    + label::before {
      right: 0;
      background-color: $switch-bg;
      border-radius: $switch-border-radius;
      transition: $switch-transition;
      z-index: 0;
    }

    + label::after {
      top: $switch-thumb-padding;
      left: 5px;
      width: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      height: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      border-radius: $switch-thumb-border-radius;
      background-color: $switch-thumb-bg;
      transition: $switch-transition;
    }

    &:checked + label::before {
      background-color: $switch-checked-bg;
    }

    &:checked + label::before > .change-style {
      left: 70%;
    }

    &:checked + label::after {
      margin-left: 4.4rem;
      color: #ffff;
    }

    &:focus + label::before {
      outline: none;
      color: #ffff !important;
      box-shadow: $switch-focus-box-shadow;
    }

    &:disabled + label {
      color: $switch-disabled-color;
      cursor: not-allowed;
    }

    &:disabled + label::before {
      color: #ffff !important;
      background-color: $switch-disabled-bg;
    }
  }

  // Small variation
  &.switch-sm {
    font-size: $font-size-sm;

    input {
      + label {
        min-width: calc(#{$switch-height-sm} * 2);
        height: $switch-height-sm;
        line-height: $switch-height-sm;
        text-indent: calc(calc(#{$switch-height-sm} * 2) + 0.5rem);
        color: #bb020b;
      }

      + label::before {
        width: calc(#{$switch-height-sm} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-sm;
      }
    }
  }

  // Large variation
  &.switch-lg {
    font-size: $font-size-lg;

    input {
      + label {
        min-width: calc(#{$switch-height-lg} * 2);
        height: $switch-height-lg;
        line-height: $switch-height-lg;
        text-indent: calc(calc(#{$switch-height-lg} * 2) + 0.5rem);
      }

      + label::before {
        width: calc(#{$switch-height-lg} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-lg;
      }
    }
  }

  + .switch {
    margin-left: 1rem;
  }
}

/* Switch Two */
.switchTwo {
  font-size: $font-size-base;
  position: relative;

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;

    + label {
      position: relative;
      min-width: calc(#{$switch-height} * 2);
      border-radius: $switch-border-radius;
      height: $switch-height;
      line-height: $switch-height;
      display: inline-block;
      cursor: pointer;
      outline: none;
      user-select: none;
      vertical-align: middle;
      color: #bb020b;
      font-weight: 600;
      font-size: 14pt;
      text-indent: calc(calc(#{$switch-height} * 2) + 0.5rem);
    }

    + label::before,
    + label::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(#{3.7rem} * 2);
      bottom: 0;
      display: block;
      z-index: 1;
    }

    + label::before {
      right: 0;
      background-color: $switch-bg;
      border-radius: $switch-border-radius;
      transition: $switch-transition;
      z-index: 0;
    }

    + label::after {
      top: $switch-thumb-padding;
      left: 5px;
      width: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      height: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      border-radius: $switch-thumb-border-radius;
      background-color: $switch-thumb-bg;
      transition: $switch-transition;
    }

    &:checked + label::before {
      background-color: $switch-checked-bg;
    }

    &:checked + label::before > .change-styleTwo {
      left: 70%;
    }

    &:checked + label::after {
      margin-left: 4.4rem;
      color: #ffff;
    }

    &:focus + label::before {
      outline: none;
      color: #ffff !important;
      box-shadow: $switch-focus-box-shadow;
    }

    &:disabled + label {
      color: $switch-disabled-color;
      cursor: not-allowed;
    }

    &:disabled + label::before {
      color: #ffff !important;
      background-color: $switch-disabled-bg;
    }
  }

  // Small variation
  &.switch-sm {
    font-size: $font-size-sm;

    input {
      + label {
        min-width: calc(#{$switch-height-sm} * 2);
        height: $switch-height-sm;
        line-height: $switch-height-sm;
        text-indent: calc(calc(#{$switch-height-sm} * 2) + 0.5rem);
        color: #bb020b;
      }

      + label::before {
        width: calc(#{$switch-height-sm} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-sm;
      }
    }
  }

  // Large variation
  &.switch-lg {
    font-size: $font-size-lg;

    input {
      + label {
        min-width: calc(#{$switch-height-lg} * 2);
        height: $switch-height-lg;
        line-height: $switch-height-lg;
        text-indent: calc(calc(#{$switch-height-lg} * 2) + 0.5rem);
      }

      + label::before {
        width: calc(#{$switch-height-lg} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-lg;
      }
    }
  }

  + .switchTwo {
    margin-left: 1rem;
  }
}

/* Switch Three */
.switchThree {
  font-size: $font-size-base;
  position: relative;

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;

    + label {
      position: relative;
      min-width: calc(#{$switch-height} * 2);
      border-radius: $switch-border-radius;
      height: $switch-height;
      line-height: $switch-height;
      display: inline-block;
      cursor: pointer;
      outline: none;
      user-select: none;
      vertical-align: middle;
      color: #bb020b;
      font-weight: 600;
      font-size: 14pt;
      text-indent: calc(calc(#{$switch-height} * 2) + 0.5rem);
    }

    + label::before,
    + label::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(#{3.7rem} * 2);
      bottom: 0;
      display: block;
      z-index: 1;
    }

    + label::before {
      right: 0;
      background-color: $switch-bg;
      border-radius: $switch-border-radius;
      transition: $switch-transition;
      z-index: 0;
    }

    + label::after {
      top: $switch-thumb-padding;
      left: 5px;
      width: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      height: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      border-radius: $switch-thumb-border-radius;
      background-color: $switch-thumb-bg;
      transition: $switch-transition;
    }

    &:checked + label::before {
      background-color: $switch-checked-bg;
    }

    &:checked + label::before > .change-styleThree {
      left: 70%;
    }

    &:checked + label::after {
      margin-left: 4.4rem;
      color: #ffff;
    }

    &:focus + label::before {
      outline: none;
      color: #ffff !important;
      box-shadow: $switch-focus-box-shadow;
    }

    &:disabled + label {
      color: $switch-disabled-color;
      cursor: not-allowed;
    }

    &:disabled + label::before {
      color: #ffff !important;
      background-color: $switch-disabled-bg;
    }
  }

  // Small variation
  &.switch-sm {
    font-size: $font-size-sm;

    input {
      + label {
        min-width: calc(#{$switch-height-sm} * 2);
        height: $switch-height-sm;
        line-height: $switch-height-sm;
        text-indent: calc(calc(#{$switch-height-sm} * 2) + 0.5rem);
        color: #bb020b;
      }

      + label::before {
        width: calc(#{$switch-height-sm} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-sm;
      }
    }
  }

  // Large variation
  &.switch-lg {
    font-size: $font-size-lg;

    input {
      + label {
        min-width: calc(#{$switch-height-lg} * 2);
        height: $switch-height-lg;
        line-height: $switch-height-lg;
        text-indent: calc(calc(#{$switch-height-lg} * 2) + 0.5rem);
      }

      + label::before {
        width: calc(#{$switch-height-lg} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-lg;
      }
    }
  }

  + .switchThree {
    margin-left: 1rem;
  }
}

.title-selector {
  text-transform: uppercase;
  color: #bb020b;
  font-size: 8pt;
  font-weight: 550;
  /* font-family: "Raleway Ligth"; */
  text-decoration: unset;
}

.subtitle-select {
  /*  font-family: "Raleway Ligth"; */
  font-size: 14pt;
  font-weight: 500;
  text-decoration: unset;
}

.change-style {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  right: 65%;
  z-index: 2;
  font-size: 10pt;
}

.change-styleNot {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  right: 2.3rem;
  color: #ffff;
  z-index: 0;
  font-size: 9pt;
  font-weight: 500;
  font-family: "Raleway";
  @media (max-width:992px) and (min-width:767px){
    &{
      top: -6.8rem;
      right: -4.2rem;
    }
  }
}

.change-styleTwo {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  right: 67%;
  z-index: 2;
  font-size: 9pt;
}

.change-styleNotTwo {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  right: 3.8rem;
  top: -0.2rem;
  color: #ffff;
  z-index: 0;
  font-size: 9pt;
  font-weight: 500;
  font-family: "Raleway";

  @media (max-width:1200px) and (min-width:767px) {
    &{
      top: -6.8rem;
      right: -4.2rem;
    }
  }
}

.change-styleThree {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  right: 67%;
  z-index: 2;
  font-size: 9pt;
}

.change-styleNotThree {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  right: 3.8rem;
  top: -0.2rem;
  color: #ffff;
  z-index: 0;
  font-size: 9pt;
  font-weight: 500;
  font-family: "Raleway";

  @media (max-width:1200px) and (min-width:767px) {
    &{
      top: -6.8rem;
      right: -4.2rem;
    }
  }
}

.input-form {
  background: #ebebeb;
  border-radius: 0%;
  font-style: italic;
  border: 1px solid transparent;
}

.backmodel {
  background-color: #e1e1e1;
  padding: 2%;
}

.backprice {
  background-color: #252525;
  padding: 2%;
  color: #ffff;
}

.btn-acept {
  font-family: "Raleway Bold";
  font-size: 12pt;
  letter-spacing: 5pt;
  text-transform: uppercase;
  background: #bb020b;
  width: auto;
  color: #ffff;
  font-weight: 600;
  border-radius: 0%;
}

@media (max-width: 992px) {
  .btn-acept {
    letter-spacing: 1pt;
  }
}

@media (max-width: 768px) {
  .btn-acept {
    margin: 0px 10px;
    letter-spacing: -1pt;
  }
}

@media (max-width: 510px) {
  .btn-acept-b {
    letter-spacing: -1pt;
    width: 150px !important;
  }
}

.nameModel {
  color: #bb020b;
  font-family: "Raleway Ligth";
  font-weight: 500;
}

.nameType {
  color: #bb020b;
  font-family: "Raleway";
  font-weight: 650;
}

.namePrice {
  font-family: "Raleway Bold";
  font-weight: 550;
}

.lineWhite {
  width: 20%;
  border: 0.5px solid #ffff;
}

.nav_up {
  padding: 7px;
  background-color: gray;
  border: 1px solid #ccc;
  position: fixed;
  background-position: 50% 50%;
  width: 50px;
  height: 50px;
  bottom: 10px;
  opacity: 0.7;
  left: 30px;
  white-space: nowrap;
  cursor: pointer;
}

.nav_down {
  padding: 7px;
  background-color: gray;
  border: 1px solid #ccc;
  position: fixed;
  background-position: 50% 50%;
  width: 50px;
  height: 50px;
  bottom: 10px;
  opacity: 0.7;
  left: 30px;
  white-space: nowrap;
  cursor: pointer;
}

.textBat {
  position: absolute;
  z-index: 2;
  font-size: 10pt;
  right: 3.2%;
  top: 4rem;
  font-weight: 550;
  background: 0 0;
  border: 1px solid transparent;
  text-align: center;
  width: 23%;
  text-transform: uppercase;
  color: black;
  text-shadow: 0.5px 0px 1px black, 0 0 0.5px white, 1px 4px 6px transparent;

  @media (min-width: 2100px) {
    & {
      font-size: 9.2pt;
      top: 4.2rem;
    }
  }

  @media (max-width: 1919px) {
    & {
      top: 4.2rem;
      font-size: 9pt;
    }
  }

  @media (max-width: 1900px) {
    & {
      top: 4.3rem;
      font-size: 8pt;
    }
  }
  @media (max-width: 1600px) {
    & {
      right: 3.2%;
      font-size: 9pt;
    }
  }

  @media (max-width: 1026px) {
    & {
      top: 3rem;
      right: 3%;
      font-size: 9pt;
    }
  }

  @media (max-width: 992px) {
    & {
      font-size: 7pt;
    }
  }

  @media (max-width: 812px) {
    & {
      width: 30%;
    }
  }

  @media (max-width: 800px) {
    & {
      top: 3.5rem;
    }
  }

  @media (max-width: 768px), handheld and (orientation: landscape) {
    & {
      /* top:2rem;
        right: 5%; */
      top: 2.2rem;
      right: 4%;
      font-size: 7pt;
    }
  }

  @media (max-width: 768px) {
    & {
      top: 3rem;
    }
  }
  @media (max-width: 700px) {
    & {
      top: 3rem;
    }
  }
  @media (max-width: 578px) {
    & {
      top: 2.7rem;
    }
  }

  @media (max-width: 540px) {
    & {
      top: 2.4rem;
    }
  }

  @media (max-width: 458px) {
    & {
      top: 2.3rem;
    }
  }

  @media (max-width: 425px) {
    & {
      /* top: 2.5rem;
        font-size: 6pt;
        right: 6%; */
      top: 2.5rem;
      font-size: 7pt;
      width: 50%;
      text-align: right;
      right: 3.5%;
    }
  }

  @media (max-width: 375px) {
    & {
      top: 2.3rem;
      font-size: 5pt;
      right: 0.5rem;
    }
  }

  @media (max-width: 360px) {
    & {
      right: 0.5rem;
      font-size: 4pt;
    }
  }

  @media (max-width: 360px) {
    & {
      font-size: 5pt;
    }
  }
}

.textModel {
  position: absolute;
  z-index: 10;
  right: 7.5rem;
  /* right: 10%; */
  color: black;
  top: 5.1rem;
  font-size: 6pt;
  font-weight: 550;
  text-shadow: 0.5px 0px 1px black, 0 0 0.5px white, 1px 4px 6px transparent;
  @media (max-width: 2100) {
    & {
      top: 5.3rem;
    }
  }
  @media (max-width: 1600) {
    & {
      top: 5rem;
    }
  }
  @media (max-width: 1200) {
    & {
      top: 5rem;
    }
  }
  @media (max-width: 1024) {
    & {
      right: 5.5rem;
    }
  }
}

.textModelOne {
  top: 3.4rem;
  /* top:3rem; */
  right: 9.4%;
  color: black;
  background-clip: text;
  text-shadow: 0.5px 0px 1px black, 0 0 0.5px white, 1px 4px 6px transparent;
}

.flexText {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0rem;
}

.textModelTwo {
  /*  right: 11%; */
  right: 5rem;
  text-shadow: 0.5px 0px 1px black, 0 0 0.5px white, 1px 4px 6px transparent;
  display: flex;
  justify-content: center;
  width: 14rem;
  @media (max-width: 2100px) {
    & {
      top: 5.2rem;
    }
  }
  @media (max-width: 1919px) {
    & {
      right: 4.5rem;
      top: 5.2rem;
    }
  }
  @media (max-width: 1900px) {
    & {
      /* top: 5.5rem!important;
            font-size: 10pt!important; */
      top: 5.3rem;
      font-size: 7pt;
      /* right: 5%; */
      right: 4.5rem;
      width: 11rem;
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 1600px) {
    & {
      right: 5rem;
    }
  }
  @media (max-width: 1024px) {
    & {
      /* top: 4.5rem;
            right: 5rem; */
      top: 3.8rem;
      right: 7rem;
      display: inherit;
      width: auto;
    }
  }
  @media (max-width: 992px) {
    & {
      right: 7rem;
    }
  }
  @media (max-width: 800px) {
    & {
      top: 4.4rem;
    }
  }
  @media (max-width: 768px) {
    & {
      /* right: 9%;
            top: 3.5rem; */
      /*  right: 8%;
            top: 3rem;
            font-size: 5pt;
            display: inherit;
            width: auto; */
      /* right: 8%; */
      right: 5.5rem;
      top: 4.5rem;
      font-size: 5pt;
      display: inherit !important;
      width: auto !important;
    }
  }
  @media (max-width: 730px) {
    & {
      top: 4.1rem;
    }
  }
  @media (max-width: 620px) {
    & {
      top: 3.6rem;
    }
  }
  @media (max-width: 560px) {
    & {
      top: 3.2rem;
    }
  }
  @media (max-width: 460px) {
    & {
      top: 2.8rem;
    }
  }
  @media (max-width: 425px) {
    & {
      /* top: 3.5rem;
            font-size: 4pt; */
      top: 3rem;
      font-size: 4pt;
      right: -1rem;
      /* display:inherit;
            width: auto;
            right: 2.5rem; */
      width: 10rem !important;
      display: flex !important;
      justify-content: center;
    }
  }
  @media (max-width: 375px) {
    & {
      top: 2.9rem;
      font-size: 3pt;
      right: 2.2rem;
      width: auto !important;

      /* right: 1.5rem; */
    }
  }
}

#bandas {
  display: none !important;
}

// .bandas2 {
//   fill: #111111;
//   display: none;
//   /* position: absolute;left:0.5%;z-index:1; */
//   position: absolute;
//   z-index: 0;
//   top: -2.5rem;
//   left: 3.5%;
//   right: -2.7rem;

//   @media (max-width: 1900px) {
//     & {
//       top: -2rem;
//     }
//   }

//   @media (max-width: 1225px) {
//     & {
//       right: -2rem;
//     }
//   }

//   @media (max-width: 1225px) {
//     & {
//       right: -2rem;
//     }
//   }

//   @media (max-width: 1225px) {
//     & {
//       right: -2rem;
//     }
//   }

//   @media (max-width: 992px) {
//     & {
//       right: -0.5rem;
//       top: -1.5rem;
//       margin-left: -2rem;
//     }
//   }

//   @media (max-width: 813px) {
//     & {
//       right: -2rem;
//       top: -1.5rem;
//       left: 2.5%;
//     }
//   }
//   @media (max-width: 768px) {
//     & {
//       top: -2rem;
//       left: 2rem;
//     }
//   }

//   @media (max-width: 725px) {
//     & {
//       right: -2rem;
//       top: -1.5rem;
//     }
//   }
//   @media (max-width: 623px) {
//     & {
//       margin-left: -2.5rem;
//     }
//   }
//   @media (max-width: 530px) {
//     & {
//       margin-left: -3rem;
//     }
//   }
//   @media (max-width: 425px) {
//     & {
//       top: -0.7rem;
//       left: -12.5%;
//       width: 40rem;
//     }
//   }

//   @media (max-width: 375px) {
//     & {
//       top: -0.7rem;
//       left: -22.6%;
//       width: 40rem;
//     }
//   }

//   @media (max-width: 320px) {
//     & {
//       left: -35.6%;
//     }
//   }
// }

.bandas3 {
  fill: #111111;
  display: none;
  /* position: absolute;left:0.5%;z-index:1; */
  position: absolute;
  left: 7%;
  z-index: 0;
  top: -1rem;
}

.imageBatMando {
  position: absolute;
  left: 1%;
  top: 0.4rem;
  z-index: 1;
  width: 55%;
  height: 9rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.imageBatBarril {
  position: absolute;
  right: 2%; //2%
  top: 1rem;
  z-index: 1;
  width: 42%; //43%
  height: 9rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.backwhite {
  background: #ffff;
  height: 12rem;
  width: 80rem;
  z-index: 1;
  position: absolute;
  top: -10rem;
}

@media (max-width: 1200px) {
  .backwhite {
    top: -11rem;
  }
}

.emblema {
  position: absolute;
  top: 3rem;
  width: 7.5rem;
  left: 57%;
  z-index: 3;
  @media (max-width: 1900px) {
    & {
      width: 7.5rem;
      top: 3rem;
    }
  }
  @media (max-width: 1200px) {
    & {
      top: 2.7rem;
      width: 6rem;
    }
  }
  @media (max-width: 1024px) {
    & {
      top: 2.3rem;
      width: 6.5rem;
    }
  }
  @media (max-width: 992px) {
    & {
      top: 2rem;
      width: 4rem;
    }
  }
  @media (max-width: 812px) {
    & {
      width: 4.5rem;
    }
  }
  @media (max-width: 768px) {
    & {
      top: 2rem;
      width: 4rem;
    }
  }
  @media (max-width: 670px) {
    & {
      top: 1.8rem;
    }
  }
  @media (max-width: 600px) {
    & {
      top: 1.5rem;
      transform: scale(0.8);
    }
  }
  @media (max-width: 550px) {
    & {
      top: 1.1rem;
    }
  }
  @media (max-width: 490px) {
    & {
      top: 1.1rem;
      transform: scale(0.6);
    }
  }
  @media (max-width: 425px) {
    & {
      top: 2.3rem;
      width: 2rem;
    }
  }
  @media (max-width: 375px) {
    & {
      left: 15.5rem;
    }
  }
  @media (max-width: 320px) {
    & {
      left: 13.5rem;
    }
  }
}

.content-edit {
  transition: all 0.2s ease-in-out;
  z-index: 1050;
  margin-top: 4rem;
}

.batTwo {
  transition: all 0.5s ease-in;
  position: relative;
  width: 80rem;
  transition: all 0.3s ease-in;

  @media (max-width: 1200px) {
    & {
      width: 70rem;
    }
  }

  @media (max-width: 992px) {
    & {
      width: 50rem !important;
    }
  }

  @media (max-width: 800px) {
    & {
      width: 100% !important;
    }
  }
}

#backG {
  background-color: #706f6f;
}

.custom-control-label {
  color: #5e5e5e;
  font-weight: 500;
}

.lineslide {
  width: 7%;
  height: 46px;
  border-left: 0.5px solid #ffff;
  border-top: 0.5px solid #ffff;
  position: relative;
  top: 3rem;
  left: -2.5%;
}

.positionTop {
  top: 5rem;
}

.btn-cart {
  border: 1.5px solid #bb020b;
  background: #bb020b;
  color: #ffff;
  letter-spacing: 1.5px;
  padding: 2%;
  border-radius: 0%;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12pt;
  transition: all 0.3s ease-in;
  text-decoration: none;
}

.btn-cart:hover {
  background: #ffff;
  text-decoration: none;
  color: #bb020b;
}

.btn-custom {
  text-decoration: none;
  border: 1.5px solid #bb020b;
  letter-spacing: 1.5px;
  background: #ffff;
  color: #bb020b;
  text-transform: uppercase;
  padding: 2%;
  border-radius: 0%;
  font-weight: 600;
  font-size: 12pt;
  transition: all 0.3s ease-in;
}

.btn-custom:hover {
  background: #bb020b;
  color: #ffff;
  text-decoration: none;
}

.page-link {
  border: none;
  color: #111111;
  font-weight: 650;
}

.page-link:hover {
  background: none;
  color: #bb020b;
}

.sizecolor {
  padding-bottom: 4rem;
  border-bottom: 1.5px solid #eee;
  z-index: 10;
  /* z-index: 999999999999999; */
}

.sizeItem {
  /* height: auto; */
  height: 14rem;
}

@media (max-width: 992px) {
  .sizeItem {
    /* height: auto; */
    height: 22rem;
  }
}

.custom .container .d-flex .batTwo .img-transparent {
  position: absolute;
  left: 0rem;
  z-index: 30;
  transition: all 0.3s ease-in-out;
}

/* .brillo{
    display:none;
}
.mate{
    display:initial;
} */
.img-sombra {
  position: absolute;
  left: 0%;
  z-index: 3;
}

.img-banda {
  display: none;
  position: absolute;
  z-index: 3;
  left: 3.9%;
  top: -0.1rem;
}

.img-madera {
  position: absolute;
  left: 0%;
  z-index: 0;
}

.sizeperson {
  /* height: 25vh; */
  height: 18vh;
  background-color: #bb020b;
}

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 40px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
}

.option-input:hover {
  background: #9faab7;
}

.option-input:checked {
  background: #bb020b;
}

.option-input:checked::before {
  height: 40px;
  width: 40px;
  position: absolute;
  content: "+"; //✔
  display: inline-block;
  font-size: 26.66667px;
  text-align: center;
  line-height: 40px;
  top: -0.3rem;
  font-weight: 600;
}

.option-input:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #bb020b;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
}

.option-input.radio {
  border-radius: 50%;
}

.option-input.radio::after {
  border-radius: 50%;
}

.box-radio {
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.product-notification {
  position: relative;
  border-radius: 50%;
  border: 1.5px solid #ffff;
  background-color: #bb020b;
  color: #fff;
  width: 20px;
  height: 20px;
  /*font-family: "Raleway Ligth";*/
  font-size: 9pt;
  padding: 5%;
  left: -36%;
  font-weight: 600;
}

.Fontdetail {
  color: #262626;
  font-size: 12pt;
  font-weight: 500;
}

.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 20rem;
}

.modelFontColor {
  color: #afafaf;
  font-weight: 500;
  font-family: "Raleway";
}

.btn-pago {
  background-color: #bb020b;
  border: 1px solid #bb020b;
  padding: 1%;
  font-weight: 600;
  font-size: 12pt;
  border-radius: 0%;
  color: #ffff;
  cursor: pointer;
}

.btn-pago:disabled {
  cursor: not-allowed;
}

.colorred {
  color: #bb020b;
  font-weight: 650;
  font-family: "Raleway";
}

.cardMetodo {
  width: 100%;
  padding: 2%;
  background-color: #f8f8f8;
  border: 1.5px solid #c2c2c2;
  margin-left: 0rem;
}

.fontFact {
  margin-bottom: 0rem;
  font-weight: 600;
  font-family: "Raleway Bold";
}

.edit {
  padding: 1%;
  border: 1px solid #c2c2c2;
  color: #bb020b;
  font-family: "Raleway";
  width: 100%;
}

.btn-outline-dark {
  border: 1px solid #bb020b !important;
  /*font-family: "Raleway";*/
  color: #bb020b;
}

.btn-outline-dark:hover {
  background: #bb020b !important;
}

.btn-dark {
  background: #bb020b !important;
  border: 1px solid #bb020b !important;
  /*font-family: "Raleway";*/
}

.hover-icon {
  transition: all 0.3s ease-in-out;
}

.hover-icon:hover {
  color: #bb020b;
}

.buttonCantidad {
  background: transparent;
  border: 1px solid #c2c2c2;
  border-radius: 0%;
}

.dropdown.open {
  margin-right: 0rem;
  margin-left: 2rem;
}

#colorEmblema {
  position: relative;
  top: -20rem;
}

/* #batStyle{
    top: -9rem;
} */
#batStyle {
  top: -9rem;
  position: absolute;
  left: 0%;
  z-index: 0;
}

@media (max-width: 992px) {
  #batStyle {
    top: -7rem !important;
  }
}

@media (max-width: 650px) {
  #batStyle {
    top: -5rem !important;
  }
}

@media (max-width: 425px) {
  #batStyle {
    top: -1rem !important;
  }
}

#batStyleTwo {
  position: absolute;
  left: 0%;
  z-index: -2;
  display: none;
}

.dropdown-menu.show {
  transition: all 0.3s ease;
}

.numberMovil {
  display: none;
}

.bandas3 {
  display: none !important;
}

.detailSize {
  width: 255px;
  // height: 255px;
  min-height: 255px;
  object-fit: contain;
}

@media (max-width: 992px) {
  .detailSize {
    width: 255px;
    min-height: 170px;
  }
}

.dropdown-menu {
  left: 0rem;
}

.text-carousel {
  width: auto;
}

.namePosition {
  left: 2.5rem;
}

@media (max-width: 1200px) {
  .namePosition {
    top: -0.5rem;
  }
}

@media (max-width: 1025px) {
  .namePosition {
    top: 0.4rem;
  }
}

@media (max-width: 992px) {
  .namePosition {
    top: -0.8rem;
  }
}

.caractFlex {
  padding-top: 2rem;
}

@media (max-width: 992px) {
  .caractFlex {
    padding-top: 0rem;
  }
}

.heightSwitch {
  height: 10rem;
  /* font-family: "Raleway Ligth"; */
}

//https://www.dropbox.com/s/xal8sws9h4qy06l/tumblr_n6hoofK5GW1r0hv8uo1_500.gif?dl=1
//RESPONSIVE

@media (min-width: 1919px) {
  .textModelOne {
    top: 3.6rem !important;
    font-size: 6pt !important;
    right: 4.4rem !important;
  }
}

@media (min-width: 1900px) {
  .batTwo {
    /*  position: fixed;
        transform: none!important;
        left: auto!important;
        top: 6.5rem!important;
        right: auto!important;
        width:81rem!important;
        z-index: 4!important; */

    position: fixed;
    left: auto !important;
    top:
            /* 6.5rem */ auto;
    right: auto !important;
    width: 81rem !important;
    z-index: 4 !important;
  }

  #batStyle {
    top: -9rem;
  }

  #badStyleTwo {
    top: -9rem;
  }

  #colorEmblema {
    top: -9rem;
  }

  .backwhite {
    top: -9rem !important;
    height: 10rem;
    width: 83rem;
  }

  .sizeperson {
    height: 20vh;
  }

  .textModelOne {
    /* font-size: 10pt!important;
        top: 3rem!important; */

    font-size: 7pt;
    top: 3.5rem;
    width: 15rem;
    display: flex;
    justify-content: center;
    right: 4.5rem;
  }

  .cuadro {
    width: 17vw;
  }
}

@media (min-width: 1600px) {
  .textModelOne {
    top: 3.4rem;
  }
}

@media (max-width: 1024px) {
  .namePosition {
    left: 0.5rem;
  }

  .navbar-brand {
    width: 30%;
  }

  .backwhite {
    width: 69rem;
    top: -12rem;
  }

  .content-edit {
    /* margin-top: 2rem!important; //-10 */
    margin-top: 2rem !important;
    z-index: 0;
  }

  .textModelOne {
    top: 2.3rem;
  }

  #batStyle {
    top: -9rem;
  }

  .imageBatBarril {
    top: 0rem;
  }

  #colorEmblema {
    top: -8rem;
  }

  .batTwo {
    position: fixed !important;
    width: 67rem;
    top: 6rem;
    z-index: 7 !important;
  }

  .dropdown-menu {
    left: -5rem;
  }

  /* .sizeperson{
        height: 12vh;
    } */
  .titleSlide {
    font-size: 14pt;
  }
}

@media (max-width: 812px) {
  .batTwo {
    width: 99%;
    left: 0rem;
  }
}

@media (max-width: 768px) {
  .title-notice {
    width: auto !important;
  }

  .dropdown-menu {
    left: -8rem;
  }

  .text-carousel {
    font-size: 10pt;
    width: 36rem;
    margin-top: 6rem;
  }

  .batTwo {
    /* width: 50rem;
        position: relative!important;
        top: 0rem; */
    width: 55rem;
    position: fixed !important;
    top: 7rem;
    left: 2rem;
  }

  .fas.fa-shopping-cart {
    color: #ffff !important;
  }

  .navbar-toggler {
    z-index: 9999;
  }

  form.d-flex {
    flex-wrap: wrap;
  }

  .positionTop {
    top: 0rem;
  }

  .checks {
    height: 50vh;
  }

  .ShopMovil {
    display: flex;
    width: 100%;
  }

  .redesFlex {
    display: none !important;
  }

  .redesMovil {
    display: inherit;
  }

  .shopFlex {
    /* display: none!important; */
    position: relative;
    /* top: -1rem;
        margin-left: 1rem; */
    top: 0rem;
    margin-left: -3rem;
  }

  .figwhite {
    width: 3rem;
    height: 2rem;
    background: #bb020b !important;
    display: none;
  }

  .figwhite:before {
    display: none;
  }

  .figwhite:after {
    display: none;
  }

  .numberShop {
    display: none;
  }

  .redes {
    /* display:none!important */
  }

  #navbartoggler {
    background-color: $red !important;
  }

  .navbar-nav {
    align-items: center;
  }

  .grid-container {
    display: grid;
    grid-template-areas:
      "foto1 foto1 foto1 "
      "foto1 foto1 foto1 "
      "foto1 foto1 foto1 "
      "foto2 foto2 foto2 "
      "foto2 foto2 foto2 "
      "foto2 foto2 foto2 "
      "foto3 foto3 foto3 "
      "foto3 foto3 foto3 "
      "foto3 foto3 foto3 "
      "foto4 foto4 foto4 "
      "foto4 foto4 foto4 "
      "foto4 foto4 foto4 ";
    background-color: transparent;
    border: 0px solid transparent;
    /* grid-gap: 5px; */
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .textModelOne {
    /* top:1.5rem; */
    top: 1.7rem;
    right: 4.2rem;
    font-size: 5pt;
  }

  .checks {
    width: 95% !important;
  }

  .backwhite {
    width: 55rem;
    top: -11rem;
    z-index: 1050;
  }

  .checkaccept {
    left: 1rem !important;
  }

  .product-notification {
    left: -22% !important;
  }

  .imageBatMando {
    top: -1.8rem;
  }

  .imageBatBarril {
    top: -1.8rem;
  }

  #batStyle {
    top: -7rem;
  }

  .item11,
  .item22,
  .item33,
  .item44 {
    margin-bottom: 1rem;
  }

  .itemSlide {
    background-position: center center !important;
  }

  .itemSlideTwo {
    background-position: center center !important;
  }

  .topShop {
    width: 100%;
    top: 10.5rem;
    left: 0.5rem;
  }

  ul.navbar-nav li.nav-item > a.nav-link {
    margin-right: 0rem !important;
  }
}

@media (max-width: 670px) {
  .batTwo {
    width: 48rem;
  }
}

@media (max-width: 425px) {
  .caractFlex {
    flex-direction: column;
  }

  .nameType {
    font-size: 14pt;
  }

  .namePosition {
    left: -0.5rem;
  }

  .dropdown-menu {
    left: -8rem;
  }

  .fas.fa-shopping-cart.fa-lg {
    position: fixed;
    /* top: 18vh;
        left: 25.5rem; */
    top: 0vh;
    left: auto;
    padding: 0.8rem;
    background: #bb020b;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
  }

  .storeMovil {
    top: 19rem;
    right: -1rem;
    width: 104%;
  }

  .numberMovil {
    display: block;
  }

  .detailSize {
    width: 320px;
  }

  .dropdown-menu.show {
    top: 3rem !important;
    left: -12rem !important;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30rem !important;
  }

  .info-product {
    width: 100% !important;
  }

  .scroll {
    height: 30rem !important;
  }

  .product-notification {
    left: -12% !important;
    padding: 4%;
  }

  .navbar-toggler {
    margin-right: 0rem;
  }

  .model-Padding {
    padding: 4rem;
  }

  @media (max-width: 425px) {
    .model-Padding {
      padding: 0 4rem;
    }
  }

  .btn-banderaActive {
    margin-left: 0rem;
  }

  .lenguaje {
    left: 1rem;
    top: -1rem !important;
  }

  .aceptar {
    flex-direction: column !important;
  }

  .input-group.d-flex.align-items-center {
    width: 3rem;
  }

  .itemCheckout {
    margin-top: 0rem;
  }

  .edit {
    margin-left: 4rem !important;
  }

  .cardMetodo {
    width: 100%;
  }

  .pago {
    width: 100% !important;
    flex-direction: column;
  }

  .btn-acept {
    font-size: 10pt;
    width: 100% !important;
  }

  .batTwo {
    width: 31rem;
    position: fixed !important;
    top: 9rem;
    left: 0rem;
  }

  #batStyle {
    top: -1rem;
  }

  form.d-flex {
    flex-direction: column;
  }

  .custom .container .d-flex .batTwo .img-transparent {
    height: 7rem;
  }

  .img-banda {
    right: -1rem;
    width: 28rem;
    height: 7.5rem;
    top: -0.3rem;
    display: none !important;
  }

  #bandas2 {
    display: none !important;
  }

  .bandas3 {
    display: block;
  }

  .img-madera {
    height: 7rem;
  }

  .img-sombra {
    height: 7rem;
  }

  .sizecolor {
    padding-bottom: 15rem;
  }

  .imageBatMando {
    height: 5rem;
    top: 0rem;
  }

  .imageBatBarril {
    height: 4rem;
    top: 1rem;
  }

  .titleEco {
    font-size: 25pt;
  }

  .title-banner {
    font-size: 30pt;
  }

  .navbar-brand.mr-0 {
    width: 80% !important;
  }

  .navbar.navbar-expand-lg {
    justify-content: space-between;
  }

  .back-footer {
    height: auto;
  }

  .titleSlide {
    font-size: 11pt;
  }

  .titleModelo {
    font-size: 50pt;
  }

  .title-bannerone {
    font-size: 15pt;
  }

  .item11 {
    background-position: center;
    margin-bottom: 1rem;
  }

  .item22 {
    background-position: center;
    margin-bottom: 1rem;
  }

  .item33 {
    background-position: center;
    margin-bottom: 1rem;
  }

  .item44 {
    background-position: center;
    margin-bottom: 1rem;
  }

  .img-top {
    width: 30%;
  }

  .text-carousel {
    font-size: 15pt !important;
    width: 20rem;
    margin-top: 1rem;
  }

  .lineslide {
    display: none;
  }

  .cuadroTwo {
    height: 2.5rem;
    width: 17rem;
  }

  .imageSlide {
    height: 27rem;
  }

  .cuadro:before {
    width: 19rem;
    height: 4rem;
  }

  .custom {
    margin-top: 8rem !important;
  }

  .textModelOne {
    /* top: 2rem;
        font-size: 5pt;
        right: 12%; */
    top: 2rem;
    font-size: 4pt;
    /* right: 2rem; */
    right: 2.5%;
  }

  .btn-acept {
    width: 100%;
  }

  .emblema-margin {
    margin-top: 15rem;
  }

  .backwhite {
    width: 32rem;
    top: -11rem;
  }

  .sizecolor {
    border-bottom: 1px solid transparent !important;
  }

  .itemSlide {
    background-position: center center !important;
  }

  .itemSlideTwo {
    background-position: center center !important;
  }

  .redes {
    height: 1.5rem !important;
  }

  .sizeItem {
    margin-top: 3rem;
  }

  .paddingCup {
    padding-top: 6rem;
  }

  .cantidad {
    padding-top: 4rem;
  }

  .displayCart {
    display: none !important;
  }

  .headerTeam {
    font-size: 7pt;
    padding-top: 1rem;
  }

  .sizeItem {
    height: 20rem;
  }

  .heightSwitch {
    height: 20%;
  }
}

@media (max-width: 375px) {
  /* .backwhite{
        width:21rem;
    }
    .batTwo{
        width: 25rem;
    } */
  .backwhite {
    width: 27rem;
  }

  .batTwo {
    width: 25rem;
    position: fixed !important;
    top: 9rem;
    left: 1rem;
  }

  .textModelOne {
    top: 2rem;
    font-size: 3pt;
    right: 0.5rem;
  }

  .fas.fa-shopping-cart.fa-lg {
    left: auto;
  }
}

@media (max-width: 390px) {
  .batTwo {
    width: 27rem;
  }
}

@media (max-width: 360px) {
  .title-bannerone {
    letter-spacing: 35px;
  }
}

@media (max-width: 360px) {
  .batTwo {
    width: 25rem;
    left: 0rem;
  }

  .titleSuscribe {
    font-size: 9pt;
  }

  .title-banner {
    font-size: 30pt;
  }

  .title-bannerone {
    letter-spacing: 24px;
  }

  .sizecolor {
    margin-top: 5rem;
  }

  .fas.fa-shopping-cart.fa-lg {
    top: -0.1vh;
    width: 3rem;
  }

  .nameType {
    font-size: 16pt;
  }
}

@media (max-width: 768px), handheld and (orientation: landscape) {
  .batTwo {
    position: fixed !important;
    left: -0.5rem;
    top: 6rem;
  }

  .emblema {
    left: 57%;
  }
}

@media (max-width: 320px) {
  .textModelOne {
    top: 2rem;
    font-size: 3pt;
    right: 1.5rem;
  }

  .batTwo {
    width: 23rem;
    left: 0rem;
    top: 6rem;
    position: fixed !important;
  }
}

// Codigo B del bate

.sizepersonB {
  /* height: 25vh; */
  margin-top: -12px;
  position: fixed;
  height: 18vh;
  background-color:white;
  width: 100%;
  left: 50%;
  transform: translate(-50%);
  z-index: 20;
  @media (max-width:1200px) {
    &{
      margin-top: -15px;
    }
  }
  @media (max-width:1026px) {
    &{
      margin-top: -18px;
    }
  }
  @media (max-width:993px) {
    &{
      margin-top: -35px;
    }
  }
  @media (max-width:773px) {
    &{
      margin-top: -50px;
    }
  }
}

.separador-bat-opciones {
  margin-top: 220px !important;
  @media (max-width:1200px) {
    &{
      margin-top: 175px !important;
    }
  }
  @media (max-width:740px) {
    &{
      margin-top: 145px !important;
    }
  }
  &-B {
    @extend .separador-bat-opciones;
    @media (max-width: 575px) {
      margin-top: 0px !important;
    }
  }
}

.batTwoB {
  transition: all 0.5s ease-in;
  position: fixed;
  width: 80vw;
  height: 100%;
  transition: all 0.3s ease-in;
  /* top: 120px; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px !important;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  z-index: 21;
}

.img-maderaB {
  position: fixed;
  left: 0%;
  z-index: 22;
  top: 50%;
  width: inherit;
  transform: translate(00%, -50%);
}

.bat-effects{
  position: fixed;
  left: 0%;
  z-index: 30;
  top: 50%;
  width: inherit;
  transform: translate(00%, -50%);
}

.img-sombraB {
  position: fixed;
  left: 0%;
  z-index: 30;
  top: 50%;
  width: inherit;
  transform: translate(00%, -50%);
}

.barrilB {
  position: fixed;
  left: 0%;
  z-index: 10;
  top: 120px;
  width: inherit;
  left: 50%;
  transform: translateX(-50%);
}

.backwhiteB {
  // background: #ffff;
  height: 12rem;
  width: 80rem;
  z-index: 1;
  position: absolute;
  top: -10rem;
}

.emblemaB {
  position: fixed;
  z-index: 29;
  top: 50%;
  height: 3.4vw;
  transform: translate(47vw, -86%);
}

.name-container {
  width: 11vw;
  position: fixed !important;
  z-index: 29;
  top: 50%;
  height: 5vw;
  transform: translate(65vw, -73%);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-brand-name {
  text-align: center;
  width: 100%;
  z-index: 27;
  color: black;
  border: 1px solid transparent;
  font-size: 1.0vw;
  text-transform: uppercase;
  font-weight: 550;
  text-shadow: 0.5px 0px .1vw black, 0 0 0.5px white, 1px 4px 6px transparent;
}

.text-model-one {
  text-align: center;
  font-size: .6vw;
  right: 9.4%;
  color: black;
  -webkit-background-clip: text;
  background-clip: text;
  text-shadow: 0.5px 0px 1px black, 0 0 0.5px white, 1px 4px 6px transparent;
}

.text-model-two {
  // margin: auto;
  text-align: center;
  font-size: .6vw;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  color: black;
  -webkit-background-clip: text;
  background-clip: text;
  text-shadow: 0.5px 0px 1px black, 0 0 0.5px white, 1px 4px 6px transparent;
}

.textModeloB {
  font-family: "Raleway Thin";
  font-weight: 400;
}

#batStyleColor {
  position: fixed;
  left: 0vw;
  z-index: 22;
  top: 50%;
  height: 10vw;
  width: 80vw;
  transform: translateY(-50%) scaleX(2);
}

.bandas2 {
  fill: #111111;
  height: 26vw;
  position: fixed;
  z-index: 24 !important;
  top: 50%;
  transform: translate(0.5vw , -48%) scale(0.9 , 0.4);
  left: -10vw !important;
}

.banda-svg{
  transform: scale(1.0, 1.5) translate(0% ,-15%);
}
